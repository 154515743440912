.root {
  display: flex;
  flex-direction: column;
  width: 43rem;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 2rem;

    .title {
      font-size: 2rem;
      font-weight: 600;
      line-height: 3rem;
      color: var(--colors-neutral-100);
    }
  }

  .filterList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 2rem 3rem;
  }
}
