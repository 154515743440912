field-element {
  display: inline;
  outline: none;

  &:focus,
  &:focus-within,
  &:visited,
  &:active,
  &:hover {
    outline: none;
  }

  &[data-type='cr']:hover,
  &[data-type='sr']:hover,
  &[data-type='cpt']:hover {
    background-color: var(--colors-blue-20);
  }

  &[data-link='true']:hover {
    cursor: pointer;
  }

  &[data-type='cpt'] {
    padding: 0 0.25rem;
  }

  &[selected='true'] {
    background-color: var(--colors-blue-20);
    border-radius: 2px;
  }

  &[data-type='cr'] {
    color: var(--colors-dark-blue-100);
  }

  &[data-type='pn']::before {
    content: counter(page);
  }
  
  &[data-type='tp']::before {
    content: '##';
  }
}
