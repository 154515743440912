:root {
  --width: 20rem;
}

.root {
  flex: 1;
  padding: 2rem 6rem 0 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  height: 8rem;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  padding-left: 6rem;
  min-height: 0;
  height: 100%;
  width: 100%;
}

.emptyList {
  flex: 1;
  text-align: center;
  padding-left: 0;
  height: 6rem;
  margin: 6rem 0 0 6rem;

  .top {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 3.5rem;
    border-top: 1px solid rgb(213, 213, 219);
  }

  .bottom {
    line-height: 2.5rem;
    font-size: 1.5rem;
    color: var(--colors-neutral-60);
    border-bottom: 1px solid var(--colors-neutral-20);
  }
}

.emptyView {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
