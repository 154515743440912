.root {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: solid 1px var(--colors-neutral-20);
  background-color: var(--colors-white);
  height: 5rem;
  padding: 1.5rem 6.5rem 1.5rem 2rem;

  &:hover {
    border-color: var(--colors-neutral-40);
    box-shadow: 0 1px 2px 0 rgba(var(--colors-black), 0.4);
  }

  &.done {
    box-shadow: inset 3px 0 0 0 #50be62;
    background-color: #ebf8ed;
  }

  &.deleted {
    box-shadow: inset 3px 0 0 0 #f2685a;
    background-color: #fdecea;
  }

  .order {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--colors-neutral-80);
    margin-right: 0.5rem;
  }

  .description {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--colors-neutral-100);
  }
}
