approve-view-element {
  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    display: flex;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE styles here */
    display: block;
  }

  ::selection {
    background-color: transparent;
  }
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
}

.iconWrapper {
  position: absolute;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  margin-left: -40px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
}

.contentWrapper {
  flex-grow: 1;
  border-radius: 2px;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
  display: flex;
  flex-direction: column;
  min-height: 36px;
  cursor: default;
}

.content {
  width: inherit;
  height: auto;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  min-height: inherit;
}

.users {
  padding: 0 20px;
  height: 32px;
  width: auto;
  margin-bottom: 0;
  box-shadow: inset 0 1px 0 0 var(--colors-neutral-20);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.userElement {
  margin: 0 4px;
}

.approvedByTextElement {
  margin: 0 4px;
}

.content > * {
  padding: 8px 24px !important;
}

.content > figure-element {
  max-width: max-content;
}
