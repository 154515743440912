.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  flex-direction: column;

  .topbar {
    position: relative;
    height: 7rem;
    width: 100%;
    background-color: var(--colors-neutral-2);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2rem;
    box-shadow: inset 0 -1px 0 var(--colors-neutral-20);

    .buttons {
      position: absolute;
      left: 2rem;
      top: 1.5rem;
      display: flex;
    }

    .title {
      display: flex;
      margin: 0 auto;
      font-weight: 600;
      font-size: 2rem;
      line-height: 3rem;
      color: var(--colors-neutral-90);
    }

    .close {
      position: absolute;
      right: 2rem;
      top: 1.5rem;
    }
  }

  .background {
    background-color: rgba(46, 46, 76, 0.5);
    flex: 1;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    overflow: auto;
  }

  .controls {
    position: absolute;
    bottom: 4rem;
    left: calc(50%);
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    height: 6rem;
    background-color: var(--colors-neutral-2);
    box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
    border-radius: 0.5rem;

    .pagination {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 3rem;
    }
  }
}
