.root {
  width: 100%;
  height: 100%;

  .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(255, 255, 255, 0.5);

    &.absolute {
      position: absolute;
      width: 100vw;
      height: 100vh;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .title {
        font-size: 4rem;
        font-weight: 600;
        line-height: 6rem;
        color: var(--colors-neutral-100);
        text-align: center;
      }

      .description {
        width: 87rem;
        margin-top: 1rem;
        font-size: 2rem;
        font-weight: 500;
        line-height: 3rem;
        color: var(--colors-neutral-100);
        text-align: center;
      }

      .footer {
        width: 100%;
        max-width: 50rem;
        margin-top: 5rem;

        .skip {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2rem;
          font-size: 2rem;
          font-weight: 500;
          line-height: 3rem;
          color: var(--colors-neutral-100);
        }
      }
    }
  }
}
