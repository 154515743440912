.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%; /*Needed to constrain the height so it can overflow*/
  min-width: 0;

  .inputContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 2rem;
  }
}

.span {
  font-size: 1.5rem;
  color: var(--colors-neutral-50);
}

.isDetails {
  margin-left: 1rem;
}

.counter {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.counterText {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  color: var(--colors-neutral-50);
  margin-right: 0.5rem;
}
