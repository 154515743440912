.panel {
  display: flex;
  flex-direction: column;
  min-height: 0;

  &.left {
    grid-area: leftpanel;
    border-left: 1px solid var(--colors-neutral-20);
    border-right: 1px solid var(--colors-neutral-20);
    // box-shadow: inset 0 0 0 1px var(--colors-neutral-20);
  }

  &.right {
    grid-area: rightpanel;
    border-left: 1px solid var(--colors-neutral-20);
  }
}
