.tags {
  margin-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
}

.textArea {
  textarea {
    height: 10rem;
  }
}
