.emptyList {
  flex: 1;
  text-align: center;
  padding-left: 0;
  height: 6rem;
  margin: 6rem 0 0 6rem;

  .top {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 3.5rem;
    border-top: 1px solid rgb(213, 213, 219);
  }

  .bottom {
    line-height: 2.5rem;
    font-size: 1.5rem;
    color: var(--colors-neutral-60);
    border-bottom: 1px solid var(--colors-neutral-20);
  }
}
