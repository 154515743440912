.menusContainer {
  display: flex;
  min-height: 50rem;
}

.menus {
  width: 21rem;
  margin-right: 2rem;
}

.option {
  height: 5rem;
  width: 21rem;
  display: flex;
  align-items: center;
  color: var(--colors-neutral-100);
  font-size: 1.75rem;
  line-height: 3rem;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;

  &.active {
    background: var(--colors-blue-5);
    border-radius: 0.5rem;
  }
}

.input {
  margin: 3rem 0;
}

.list {
  flex: 1;
  overflow: auto;
  max-height: 500px;
  margin-top: 3rem;

  .metadataOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: var(--colors-blue-10);
    height: 5rem;
    padding: 1rem 2rem;
    color: var(--colors-neutral-100);
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
}

.emptyList {
  display: flex;
  flex: 1;
  justify-content: center;
}

.editContainer {
  border-radius: 4px;
  background: var(--colors-blue-10);
  height: 5rem;
  padding: 1rem 2rem;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 3rem;
  color: var(--colors-neutral-100);
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.loading {
  margin-top: 8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    margin-top: 2rem;
    color: var(--colors-neutral-100);
    font-size: 1.75rem;
    line-height: 3rem;
    font-weight: 500;
  }
}
