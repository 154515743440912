.note {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 7rem;
  padding: 1.5rem 1rem;
  border-radius: 0;
  border: 0;
  outline: 0;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-family: Inter;
}
