.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 6rem 0 0;
  overflow: hidden;

  .header {
    display: flex;
    height: 8rem;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  padding-left: 6rem;
  min-height: 0;
  height: 100%;
  width: 100%;

  > div:first-child {
    height: 100%;
    width: 100%;
    // display: flex;
  }

  .listView {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
}
