loader-element {
  /* display: none; */
  margin-top: 5px;
  margin-bottom: 5px;
  width: auto;
  user-select: none;
  max-width: '946px';

  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    display: flex;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE styles here */
    display: block;
  }
}

.container {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 2px 0;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-neutral-5);
  border: solid 1px var(--colors-neutral-20);
  color: var(--colors-neutral-60) !important;
  outline: 0;
}

.info {
  font-family: Inter !important;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
