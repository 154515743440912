.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 6rem 1rem;

  .page {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 0;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 6rem;
    font-weight: 600;
    color: var(--colors-neutral-80);
    border-bottom: 1px solid var(--colors-neutral-20);

    .name {
      padding-left: 2rem;
      width: 26rem;
    }

    .creationDate {
      padding-left: 2rem;
      width: 15rem;
    }

    .description {
      padding-left: 2rem;
    }
  }

  .row {
    display: flex;
    align-items: flex-start;
    padding-top: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid var(--colors-neutral-20);
    min-height: 5rem;

    .name {
      width: 26rem;
      padding-left: 2rem;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .creationDate {
      padding-left: 2rem;
      width: 15rem;
    }

    .description {
      flex: 1;
      padding-left: 2rem;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .buttons {
      display: flex;
    }
  }
}
