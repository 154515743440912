.root {
  display: flex;
  gap: 2rem;

  &.fitContent {
    width: fit-content;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.radioGroup {
      padding: 1rem 0;
      gap: 1rem;
    }

    .row {
      display: flex;
      gap: 1rem;

      &.flexChildren > * {
        flex: 1;
      }

      &.withMargin {
        margin-top: 1rem;
      }
    }

    .shelf {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1.5rem;
      column-gap: 1rem;
    }
  }
}
