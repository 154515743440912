.root {
  display: flex;
  flex-direction: column;

  width: 35rem;
  min-width: 35rem;
  margin-left: 5rem;

  .body {
    overflow: auto;
  }

  .emptyState {
    margin-top: 9rem;
    overflow: auto;
  }
}
