.root {
  width: 100%;

  .item {
    display: flex;
    align-items: center;
    padding-left: 4rem;
    height: 4rem;
    cursor: pointer;
    position: relative;
    color: var(--colors-neutral-100);
    outline: none;

    .shortcut {
      margin-left: auto;
      height: 100%;
      padding-right: 1rem;
      display: flex;
      align-items: center;
      color: var(--colors-neutral-80);
      font-size: 1.5rem;
    }

    &.disabled {
      cursor: not-allowed;
      color: var(--colors-neutral-20);

      .shortcut {
        color: var(--colors-neutral-20);
      }

      &:focus {
        outline: 0;
      }
    }

    &:hover:not(.disabled) {
      background: var(--colors-blue-10);

      .icon {
        opacity: 1;
      }
    }
  }
}
