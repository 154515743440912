.root {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  height: 4rem;

  font-size: 1.5rem;
  line-height: 2rem;

  color: var(--colors-neutral-50);
  background-color: var(--colors-neutral-10);

  border-radius: 100%;
}
