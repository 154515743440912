.root {
  display: flex;

  > * {
    display: flex;
    align-items: center;
  }
}

.left {
  margin-right: auto;
}

.right {
  margin-left: auto;
}
