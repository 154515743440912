.content {
  grid-area: content;
  background: var(--colors-neutral-20);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  outline: none;

  &.versionHistory {
    background: var(--colors-neutral-40);
  }

  .pan {
    cursor: grab;

    &.panning {
      cursor: grabbing;
      user-select: none;
    }
  }

  .onePage {
    margin-top: 4rem;
  }
}

.overlap {
  will-change: unset !important;
}