.root {
  margin-top: 1rem;
  display: flex;
  align-items: center;

  .text {
    margin-left: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
