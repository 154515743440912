hyperlink-element {
  display: inline;
  outline: none;
  color: var(--colors-blue-100);
  text-decoration: underline;

  &:hover,
  &[selected='true'] {
    background-color: var(--colors-blue-20);
    border-radius: 2px;
  }
}
