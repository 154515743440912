.extensors {
  position: absolute;
  border-radius: 0.5rem;
  background: var(--colors-neutral-5);
  cursor: pointer;

  &.column {
    display: flex;
    left: 0;
    top: -3rem;
    height: 2rem;
    box-shadow: 0px -8px 4px 4px var(--colors-white);
  }

  &.row {
    top: 0;
    left: -3rem;
    width: 2rem;
  }
  > .extensor {
    font-family: Inter;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--colors-neutral-60);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--colors-neutral-50);
    border-collapse: collapse;
    position: relative;

    &.column {
      border-right: 0;

      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &.last {
        border-right-width: 1px;
        border-right-style: solid;
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }
    &.row {
      border-bottom: 0;

      &:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
      }

      &.last {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }

    &:hover,
    &.partially-selected {
      background: var(--colors-blue-20);
      border-color: var(--colors-blue-90);
      color: var(--colors-blue-90);
    }

    &.selected {
      background: var(--colors-blue-70);
      border-color: var(--colors-blue-105);
      color: var(--colors-white);
    }

    &.deleting {
      background: var(--colors-red-20);
      border-color: var(--colors-red-90);
      color: var(--colors-red-90);
    }
  }
}

.cells {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  .cell {
    position: absolute;
    box-shadow: inset 0px 0px 0px 1px #ffffff, inset 0px 0px 0px 3px #508df7;
    background: rgba(36, 112, 245, 0.13);
  }

  path {
    fill: rgba(36, 112, 245, 0.13);
  }

  &.deleting {
    path {
      fill: rgba(239, 66, 49, 0.1);
    }
  }
}

.shortcut {
  position: absolute;

  display: flex;
  z-index: 1;
  cursor: pointer;

  .hidden {
    display: none;
    position: absolute;

    .icon {
      &:hover {
        .pau {
          display: block;
        }
      }
    }

    .icon {
      height: 13px;
      width: 13px;

      .pau {
        display: none;
        position: absolute;
        background: var(--colors-blue-80);
      }
    }
  }

  &.top {
    width: 50%;
    height: 23px;
    bottom: 0;
    justify-content: flex-start;
    left: -2px;

    + .shortcut {
      justify-content: flex-end;
      left: unset;
      right: -2px;

      .hidden {
        transform: translate(calc(50% - 2px), 0);
      }
    }

    .hidden {
      top: -9px;
      transform: translate(calc(-50% + 1px), 0);

      .pau {
        top: calc(100% + 4px);
        left: 6px;
        width: 1px;
        height: 24px;
      }
    }
  }

  &.side {
    height: 50%;
    width: 23px;
    right: 0;
    align-items: flex-start;
    top: -2px;

    + .shortcut {
      align-items: flex-end;
      top: unset;
      bottom: -2px;

      .hidden {
        transform: translate(0, calc(50% - 2px));
      }
    }

    .hidden {
      left: -10px;
      transform: translate(0, calc(-50% + 1px));

      .pau {
        top: 7px;
        left: calc(100% + 4px);
        width: 24px;
        height: 1px;
      }
    }
  }

  .dot {
    background: var(--colors-neutral-30);
    height: 3px;
    width: 3px;
    border-radius: 3px;
  }

  &:not(.first) {
    .dot {
      display: none;
    }
  }

  + .shortcut {
    .dot {
      display: block;
    }
  }

  &.enabled {
    &:hover {
      .dot {
        display: none;
      }
      .hidden {
        display: block;
      }
    }
  }
}

.remover-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .remover {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}

.anchors {
  position: absolute;
  top: 0;
  left: 0;
}

.anchor {
  position: absolute;
  z-index: 2;

  &.ghost {
    opacity: 0.25;
    background-color: var(--colors-blue-100);
  }

  &.row {
    height: 4px;
    left: 0;
    transform: translate(0, -1.5pt);
    cursor: ns-resize;

    &.extensor {
      left: -24px;
    }
  }

  &.column {
    width: 4px;
    top: 0;
    cursor: ew-resize;

    &.extensor {
      top: -24px;
    }
  }
}
