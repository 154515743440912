.footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'left right';
  background-color: var(--colors-neutral-2);
  width: 100%;

  &.editor {
    padding: 0 1rem;
  }

  > * {
    display: flex;
    align-items: center;
  }

  .left {
    grid-area: left;
  }
  .right {
    grid-area: right;
    justify-self: end;
  }
}
